const optionCourse = [
	{
		label: "AB-C - BACHELOR OF ARTS   - MAJOR IN COMMUNICATION",
		value: "AB-C",
	},
	{
		label: "AB-CA - BACHELOR OF ARTS   - MAJOR IN COMMUNICATION ARTS",
		value: "AB-CA",
	},
	{
		label: "AB-ECON - BACHELOR OF ARTS  - MAJOR IN  ECONOMICS",
		value: "AB-ECON",
	},
	{
		label: "AB-ELS - BACHELOR OF ARTS IN ENGLISH LANGUAGE STUDIES ",
		value: "AB-ELS",
	},
	{
		label: "AB-ENGLANG - BACHELOR OF ARTS  -  MAJOR IN ENGLISH LANGUAGE",
		value: "AB-ENGLANG",
	},
	{
		label: "AB-FILLANG - BACHELOR OF ARTS  - FILIPINO LANGUAGE",
		value: "AB-FILLANG",
	},
	{
		label: "AB-GC - BACHELOR OF ARTS   -  MAJOR IN GUIDANCE AND COUNSELING",
		value: "AB-GC",
	},
	{ label: "AB-MC - BACHELOR OF ARTS   - MASS COMMUNICATION", value: "AB-MC" },
	{
		label: "AB-PS - BACHELOR OF ARTS   - MAJOR IN  POLITICAL SCIENCE",
		value: "AB-PS",
	},
	{
		label:
			"ACAD-ABM-ABP - ACADEMIC ABM-ABP MORELOS  - ACCOUNTANCY BUSINESS AND MANAGEMENT STRAND",
		value: "ACAD-ABM-ABP",
	},
	{
		label:
			"ACAD-ABM-BPP - ACADEMIC ABM-BP PUEBLOS  - ACCOUNTANCY BUSINESS AND MANAGEMENT STRAND",
		value: "ACAD-ABM-BPP",
	},
	{
		label: "ACAD-GAS-ABP - ACADEMIC GAS-ABP MORELOS  - GENERAL ACADEMIC STRAND",
		value: "ACAD-GAS-ABP",
	},
	{
		label: "ACAD-GAS-BPP - ACADEMIC GAS-BP PUEBLOS  - GENERAL ACADEMIC STRAND",
		value: "ACAD-GAS-BPP",
	},
	{
		label:
			"ACAD-HUMSS-ABP - ACADEMIC HUMSS-ABP MORELOS  - HUMANITIES AND SOCIAL SCIENCES STRAND",
		value: "ACAD-HUMSS-ABP",
	},
	{
		label:
			"ACAD-HUMSS-BPP - ACADEMIC HUMSS-BP PUEBLOS  - HUMANITIES AND SOCIAL SCIENCES STRAND",
		value: "ACAD-HUMSS-BPP",
	},
	{
		label:
			"ACAD-STEM-ABP - ACADEMIC STEM-ABP MORELOS  - SCIENCE, TECHNOLOGY, ENGINEERING, AND MATHEMATICS STRAND",
		value: "ACAD-STEM-ABP",
	},
	{
		label:
			"ACAD-STEM-BPP - ACADEMIC STEM-BP PUEBLOS  - SCIENCE, TECHNOLOGY, ENGINEERING, AND MATHEMATICS STRAND",
		value: "ACAD-STEM-BPP",
	},
	{ label: "AT - Automotive Technology ", value: "AT" },
	{
		label: "ATC1 - Automotive Technology-Clustered (One Year) ",
		value: "ATC1",
	},
	{ label: "AUDIT_ACC - AUDIT UNDERGRADUATE_ACCOUNTANCY ", value: "AUDIT_ACC" },
	{ label: "BECE - BACHELOR OF EARLY CHILDHOOD EDUCATION ", value: "BECE" },
	{ label: "BECED - BACHELOR OF EARLY CHILDHOOD EDUCATION  ", value: "BECED" },
	{
		label:
			"BEE - BACHELOR OF ELEMENTARY EDUCATION   - (WITH SPECIALIZATION IN SPECIAL EDUCATION)",
		value: "BEE",
	},
	{
		label:
			"BEE-PRES.ED. - BACHELOR OF ELEMENTARY EDUCATION   - (WITH SPECIALIZATION IN PRE-SCHOOL EDUCATION)",
		value: "BEE-PRES.ED.",
	},
	{ label: "BEED - BACHELOR OF ELEMENTARY EDUCATION  ", value: "BEED" },
	{ label: "BEEG - BACHELOR OF ELEMENTARY EDUCATION  ", value: "BEEG" },
	{
		label:
			"BEEPE - BACHELOR OF ELEMENTARY EDUCATION   - (WITH SPECIALIZATION IN PRESCHOOL EDUCATION)",
		value: "BEEPE",
	},
	{
		label:
			"BEESE - BACHELOR OF ELEMENTARY EDUCATION   - SPECIALIZATION IN SPECIAL EDUCATION",
		value: "BEESE",
	},
	{ label: "BHUMSERV - BACHELOR IN HUMAN SERVICES ", value: "BHUMSERV" },
	{
		label: "BLIS - BACHELOR OF LIBRARY AND INFORMATION SCIENCE ",
		value: "BLIS",
	},
	{
		label: "BPE - BACHELOR OF PHYSICAL EDUCATION  -   MAJOR IN SCHOOL P.E.",
		value: "BPE",
	},
	{ label: "BPED - BACHELOR OF PHYSICAL EDUCATION ", value: "BPED" },
	{ label: "BS PSYCH - BACHELOR OF SCIENCE IN PSYCHOLOGY ", value: "BS PSYCH" },
	{ label: "BSA - BACHELOR OF SCIENCE IN ACCOUNTANCY ", value: "BSA" },
	{
		label:
			"BSAAM - BACHELOR OF SCIENCE IN ACCOUNTANCY MAJOR IN ACCOUNTING MANAGEMENT ",
		value: "BSAAM",
	},
	{
		label: "BSAIS - BACHELOR OF SCIENCE IN ACCOUNTING INFORMATION SYSTEM ",
		value: "BSAIS",
	},
	{
		label: "BSAM - BACHELOR OF SCIENCE IN APPLIED MATHEMATICS ",
		value: "BSAM",
	},
	{
		label: "BSAT - BACHELOR OF SCIENCE IN ACCOUNTING TECHNOLOGY ",
		value: "BSAT",
	},
	{
		label:
			"BSBA-FM - BACHELOR OF SCIENCE IN BUSINESS ADMINISTRATION   -  MAJOR IN FINANCIAL MANAGEMENT",
		value: "BSBA-FM",
	},
	{
		label:
			"BSBA-HRDM - BACHELOR OF SCIENCE IN BUSINESS ADMINISTRATION   - MAJOR IN  HUMAN RESOURCE DEVELOPMENT MANAGEMENT",
		value: "BSBA-HRDM",
	},
	{
		label:
			"BSBA-HRMGT - BACHELOR OF SCIENCE IN BUSINESS ADMINISTRATION  - MAJOR IN HUMAN RESOURCE MANAGEMENT",
		value: "BSBA-HRMGT",
	},
	{
		label:
			"BSBA-MM - BACHELOR OF SCIENCE IN BUSINESS ADMINISTRATION   -  MAJOR IN MARKETING MANAGEMENT",
		value: "BSBA-MM",
	},
	{
		label:
			"BSBA-OM - BACHELOR OF SCIENCE IN BUSINESS ADMINISTRATION   -  MAJOR IN OPERATIONS MANAGEMENT",
		value: "BSBA-OM",
	},
	{ label: "BSBIO - BACHELOR OF SCIENCE IN  BIOLOGY ", value: "BSBIO" },
	{
		label:
			"BSC-ACM - BACHELOR OF SCIENCE IN COMMERCE  - MAJOR IN ACCOUNTING MANAGEMENT",
		value: "BSC-ACM",
	},
	{
		label:
			"BSC-BIS - BACHELOR OF SCIENCE IN COMMERCE   -  MAJOR IN BUSINESS INFORMATION SYSTEM",
		value: "BSC-BIS",
	},
	{
		label: "BSC-F - BACHELOR OF SCIENCE IN COMMERCE  -  MAJOR IN FINANCE",
		value: "BSC-F",
	},
	{
		label:
			"BSC-LM - BACHELOR OF SCIENCE IN COMMERCE   - MAJOR IN LEGAL MANAGEMENT",
		value: "BSC-LM",
	},
	{
		label: "BSC-MK - BACHELOR OF SCIENCE IN COMMERCE   - MAJOR IN MARKETING",
		value: "BSC-MK",
	},
	{
		label: "BSC-MN - BACHELOR OF SCIENCE IN COMMERCE   - MAJOR IN MANAGEMENT",
		value: "BSC-MN",
	},
	{ label: "BSCE - BACHELOR OF SCIENCE IN CIVIL ENGINEERING ", value: "BSCE" },
	{ label: "BSCRIM - BACHELOR OF SCIENCE IN CRIMINOLOGY ", value: "BSCRIM" },
	{ label: "BSCS - BACHELOR OF SCIENCE IN COMPUTER SCIENCE ", value: "BSCS" },
	{ label: "BSE - BACHELOR OF SCIENCE IN ENTREPRENEURSHIP ", value: "BSE" },
	{
		label:
			"BSE-BS - BACHELOR OF SECONDARY EDUCATION   - MAJOR IN BIOLOGICAL SCIENCE",
		value: "BSE-BS",
	},
	{
		label: "BSE-E - BACHELOR OF SECONDARY EDUCATION   - MAJOR IN ENGLISH",
		value: "BSE-E",
	},
	{
		label: "BSE-F - BACHELOR OF SECONDARY EDUCATION   - MAJOR IN FILIPINO",
		value: "BSE-F",
	},
	{
		label: "BSE-M - BACHELOR OF SECONDARY EDUCATION   - MAJOR IN  MATHEMATICS",
		value: "BSE-M",
	},
	{
		label: "BSE-MAPEH - BACHELOR OF SECONDARY EDUCATION   -  MAJOR IN MAPEH",
		value: "BSE-MAPEH",
	},
	{
		label:
			"BSE-PS - BACHELOR OF SECONDARY EDUCATION   -  MAJOR IN PHYSICAL SCIENCE",
		value: "BSE-PS",
	},
	{
		label: "BSE-S - BACHELOR OF SECONDARY EDUCATION  - MAJOR IN SCIENCE",
		value: "BSE-S",
	},
	{
		label:
			"BSE-SS - BACHELOR OF SECONDARY EDUCATION   -  MAJOR IN SOCIAL STUDIES",
		value: "BSE-SS",
	},
	{
		label: "BSED - BACHELOR OF SECONDARY EDUCATION  - (UNIT EARNER)",
		value: "BSED",
	},
	{
		label:
			"BSEMC-DA - BACHELOR OF SCIENCE IN ENTERTAINMENT AND MULTIMEDIA COMPUTING-DA  - DIGITAL ANIMATION",
		value: "BSEMC-DA",
	},
	{
		label:
			"BSEMC-GD - BACHELOR OF SCIENCE IN ENTERTAINMENT AND MULTIMEDIA COMPUTING-GD  - GAME DEVELOPMENT",
		value: "BSEMC-GD",
	},
	{ label: "BSF - BATSILYER NG SINING SA FILIPINO ", value: "BSF" },
	{
		label: "BSHM - BACHELOR OF SCIENCE IN HOSPITALITY MANAGEMENT ",
		value: "BSHM",
	},
	{
		label: "BSHM REV. - BACHELOR OF SCIENCE IN HOSPITALITY MANAGEMENT REV. ",
		value: "BSHM REV.",
	},
	{
		label: "BSHRM - BACHELOR OF SCIENCE IN HOTEL AND RESTAURANT MANAGEMENT ",
		value: "BSHRM",
	},
	{ label: "BSIA - BACHELOR OF SCIENCE IN INTERNAL AUDITING ", value: "BSIA" },
	{
		label: "BSIE - BACHELOR OF SCIENCE IN INDUSTRIAL ENGINEERING ",
		value: "BSIE",
	},
	{
		label: "BSISM - BACHELOR OF SCIENCE IN INDUSTRIAL SECURITY MANAGEMENT ",
		value: "BSISM",
	},
	{
		label: "BSIT - BACHELOR OF SCIENCE IN INFORMATION TECHNOLOGY ",
		value: "BSIT",
	},
	{
		label:
			"BSIT-CA - BACHELOR OF SCIENCE IN INFORMATION TECHNOLOGY   - (WITH SPECIAL TRAINING COURSES IN COMPUTER ANIMATION)",
		value: "BSIT-CA",
	},
	{
		label: "BSMA - BACHELOR OF SCIENCE IN MANAGEMENT ACCOUNTING ",
		value: "BSMA",
	},
	{ label: "BSN - BACHELOR OF SCIENCE IN NURSING ", value: "BSN" },
	{ label: "BSNED - BACHELOR OF SPECIAL NEEDS EDUCATION  ", value: "BSNED" },
	{
		label: "BSOA - BACHELOR OF SCIENCE IN OFFICE ADMINISTRATION ",
		value: "BSOA",
	},
	{
		label:
			"BSOA-IOM - BACHELOR OF SCIENCE IN OFFICE ADMINISTRATION  - WITH SPECIALIZATION IN INDUSTRIAL OFFICE MANAGEMENT",
		value: "BSOA-IOM",
	},
	{
		label:
			"BSOA-LOM - BACHELOR OF SCIENCE IN OFFICE ADMINISTRATION  - WITH SPECIALIZATION IN LEGAL OFFICE MANAGEMENT",
		value: "BSOA-LOM",
	},
	{ label: "BSPEd - BACHELOR OF SPECIAL EDUCATION ", value: "BSPEd" },
	{
		label: "BSSE - BACHELOR OF SCIENCE IN SOCIAL ENTREPRENEURSHIP ",
		value: "BSSE",
	},
	{
		label:
			"BSSE-AB - BACHELOR OF SCIENCE IN SOCIAL ENTREPRENEURSHIP   - WITH SPECIALIZATION IN AGRI-AQUA BUSINESS",
		value: "BSSE-AB",
	},
	{
		label:
			"BSSE-ACB - BACHELOR OF SCIENCE IN SOCIAL ENTREPRENEURSHIP   - WITH SPECIALIZATION IN ARTS AND CRAFTS BUSINESS",
		value: "BSSE-ACB",
	},
	{ label: "BSTM - BACHELOR OF SCIENCE IN TOURISM MANAGEMENT ", value: "BSTM" },
	{ label: "CES1 - Consumer Electronics Servicing NC II ", value: "CES1" },
	{ label: "CHS - Computer Hardware Servicing NC II ", value: "CHS" },
	{ label: "CS - Computer Secretarial ", value: "CS" },
	{ label: "DBA - DOCTOR IN BUSINESS ADMINISTRATION ", value: "DBA" },
	{
		label: "DHRST - DIPLOMA IN HOTEL AND RESTAURANT SERVICES TECHNOLOGY ",
		value: "DHRST",
	},
	{ label: "DIT - DIPLOMA IN INFORMATION TECHNOLOGY ", value: "DIT" },
	{
		label: "DM - DOCTOR OF MANAGEMENT IN ORGANIZATIONAL MANAGEMENT ",
		value: "DM",
	},
	{
		label: "DM-OM - DOCTOR OF MANAGEMENT  - MAJOR IN ORGANIZATIONAL MANAGEMENT",
		value: "DM-OM",
	},
	{ label: "DT - Drafting Technology ", value: "DT" },
	{ label: "DT1 - Drafting Technology (One Year) ", value: "DT1" },
	{ label: "ETC - Electronic Technician Course ", value: "ETC" },
	{ label: "GEN.ED-BAP - GENERAL EDUCATION ", value: "GEN.ED-BAP" },
	{ label: "GEN.ED-TE - GENERAL EDUCATION ", value: "GEN.ED-TE" },
	{ label: "GS - GRADE SCHOOL ", value: "GS" },
	{ label: "HMDP - HOTEL AND RESTAURANT SERVICES TECHNOLOGY ", value: "HMDP" },
	{ label: "HS - HIGH SCHOOL ", value: "HS" },
	{ label: "ITDP - INFORMATION TECHNOLOGY DIPLOMA PROGRAM ", value: "ITDP" },
	{ label: "JD - Juris Doctor ", value: "JD" },
	{ label: "LAW - JURIS DOCTOR ", value: "LAW" },
	{
		label:
			"MACSEA - MASTER OF ARTS IN COMMUNITY STUDIES AND EXTENSION ADMINISTRATION ",
		value: "MACSEA",
	},
	{ label: "MAEM - MASTER OF ARTS IN EDUCATIONAL MANAGEMENT ", value: "MAEM" },
	{ label: "MAGC - MASTER OF ARTS IN GUIDANCE AND COUNSELING ", value: "MAGC" },
	{
		label:
			"MAN-MSN - MASTER OF ARTS IN NURSING   - MAJOR IN MEDICAL-SURGICAL NURSING",
		value: "MAN-MSN",
	},
	{
		label:
			"MAN-NSA - MASTER OF ARTS IN NURSING   - MAJOR IN NURSING SUPERVISION AND ADMINISTRATION",
		value: "MAN-NSA",
	},
	{
		label:
			"MANM - MASTER OF ARTS IN NURSING  - WITH SPECIAL TRAINING COURSES IN MEDICAL-SURGICAL NURSING",
		value: "MANM",
	},
	{
		label:
			"MANN - MASTER OF ARTS IN NURSING  - WITH SPECIAL TRAINING COURSES IN NURSING SUPERVISION ",
		value: "MANN",
	},
	{ label: "MATE - MASTER OF ARTS IN TEACHING ENGLISH ", value: "MATE" },
	{ label: "MATF - MASTER OF ARTS IN TEACHING FILIPINO ", value: "MATF" },
	{
		label: "MATGS - MASTER OF ARTS IN TEACHING GENERAL SCIENCE ",
		value: "MATGS",
	},
	{
		label: "MATSE - MASTER OF ARTS IN TEACHING SPECIAL EDUCATION ",
		value: "MATSE",
	},
	{ label: "MBA - MASTER IN BUSINESS ADMINISTRATION ", value: "MBA" },
	{
		label:
			"MBA-BM - MASTER IN BUSINESS ADMINISTRATION  - (WITH SPECIALIZATION IN BUSINESS MANAGEMENT)",
		value: "MBA-BM",
	},
	{
		label:
			"MBA-HR - MASTER IN BUSINESS ADMINISTRATION  - (WITH SPECIALIZATION IN HUMAN RESOURCE MANAGEMENT)",
		value: "MBA-HR",
	},
	{
		label:
			"MBA-HRM - MASTER OF  BUSINESS ADMINISTRATION   - HUMAN RESOURCE MANAGEMENT",
		value: "MBA-HRM",
	},
	{ label: "MNC1 - Machining NC II ", value: "MNC1" },
	{ label: "MPA - MASTER IN PUBLIC ADMINISTRATION  ", value: "MPA" },
	{ label: "MSPE - MASTER OF SCIENCE IN PHYSICAL EDUCATION ", value: "MSPE" },
	{ label: "MST - MACHINE SHOP TECHNOLOGY ", value: "MST" },
	{ label: "MSTM - MASTER OF SCIENCE IN TEACHING MATHEMATICS ", value: "MSTM" },
	{ label: "PCO - PC Operations NC II ", value: "PCO" },
	{ label: "PHD - DOCTOR OF PHILOSOPHY IN EDUCATION ", value: "PHD" },
	{ label: "PRG - Programming NC IV ", value: "PRG" },
	{ label: "PS - PRE-SCHOOL ", value: "PS" },
	{ label: "SMAW - SHIELDED METAL ARC WELDING NC II ", value: "SMAW" },
	{
		label: "TEDUE - TEACHER EDUCATION UNIT EARNER'S COURSE (TEDUE) ",
		value: "TEDUE",
	},
];
export default optionCourse;
