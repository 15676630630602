import React, { useEffect, useState } from "react";
import { Form, Col, Row, Image } from "antd";
import { apiUrl, defaultProfile } from "../../../providers/companyInfo";
import RegisterStudentProfile from "../../public/PageRegister/RegisterStudentProfile";
import RegisterStudentAddress from "../../public/PageRegister/RegisterStudentAddress";
import RegisterStudentSchool from "../../public/PageRegister/RegisterStudentSchool";
import RegisterParentProfile from "../../public/PageRegister/RegisterParentProfile";
import RegisterParentProfile1 from "../../public/PageRegister/RegisterParentProfile1";
import RegisterAcademicProfile from "../../public/PageRegister/RegisterAcademicProfile";
import RegisterAdditionalInfo from "../../public/PageRegister/RegisterAdditionalInfo";

export default function PageStudentProfile(props) {
	const [form] = Form.useForm();

	const { dataUserProfileInfo } = props;

	const [profileInfo, setProfileInfo] = useState({
		image: defaultProfile,
		username: "",
		role: "",
	});

	useEffect(() => {
		if (dataUserProfileInfo) {
			if (dataUserProfileInfo.profile) {
				let image = defaultProfile;
				let username = `${dataUserProfileInfo.profile.firstname} ${dataUserProfileInfo.profile.lastname}`;
				let role = `${dataUserProfileInfo.user_role.role}`;
				if (dataUserProfileInfo.profile.attachments.length) {
					image = apiUrl + dataUserProfileInfo.profile.attachments[0].file_path;
				}

				setProfileInfo({
					image,
					username,
					role,
				});
			}
		}

		return () => {};
	}, [dataUserProfileInfo]);

	return (
		<Form form={form} layout="vertical">
			<Row gutter={[12, 0]} className="student-profile-container">
				<Col xs={24} sm={24} md={24} lg={24} className="student-profile-image">
					<Image
						preview={false}
						src={profileInfo.image}
						alt={profileInfo.username}
					/>
				</Col>
			</Row>

			<Row gutter={[12, 0]} className="student-info-container">
				<Col xs={24} sm={24} md={24} lg={24} className="student-info-wrapper">
					<Col xs={24} sm={24} md={24} lg={24} className="student-profile pl-0">
						<div className="student-banner">
							<p>Student Profile</p>
						</div>
						<RegisterStudentProfile />
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} className="student-address pl-0">
						<RegisterStudentAddress />
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} className="student-school pl-0">
						<RegisterStudentSchool />
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} className="family-info pl-0">
						<div className="family-banner">
							<p>Family Profile</p>
						</div>
						<RegisterParentProfile />
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} className="family-info-1 pl-0">
						<RegisterParentProfile1 />
					</Col>

					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						className="academic-profile pl-0"
					>
						<div className="academic-banner">
							<p>Academic Profile</p>
						</div>
						<RegisterAcademicProfile />
					</Col>

					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						className="additional-profile pl-0"
					>
						<div className="additional-banner">
							<p>Additional Information</p>
						</div>
						<RegisterAdditionalInfo />
					</Col>
				</Col>
			</Row>
		</Form>
	);
}
