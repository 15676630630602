import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import moment from "moment-timezone";

export default function PageEntranceExamCalendar(props) {
	const { dataSource } = props;

	// To customize the calendar
	const calendarRef = useRef(null);

	moment.tz.setDefault("Asia/Manila");

	let eventCount = 0;

	const eventData = dataSource.data.data.map(
		({ total_available_slot, slots, exam_start, exam_end, exam_date }) => ({
			title: `Exam Schedule  ${total_available_slot} / ${slots}`,
			time: `( ${exam_start} - ${exam_end} )`,
			start: moment(`${exam_date} ${exam_start}`, "YYYY-MM-DD hh:mm A").format(
				"YYYY-MM-DD HH:mm:ss"
			),
			end: moment(`${exam_date} ${exam_end}`, "YYYY-MM-DD hh:mm A").format(
				"YYYY-MM-DD HH:mm:ss"
			),
			color: total_available_slot === 0 ? "#FE0000" : "green",
			classNames: total_available_slot === 0 ? "red" : "green",
		})
	);

	const currentDay = new Date().toLocaleString("en-us", {
		weekday: "short",
	});

	return (
		currentDay && (
			<div className="container">
				<FullCalendar
					// ref to the calendar to access the calendar object
					ref={calendarRef}
					height={650}
					dayMaxEvents={true}
					displayEventTime={false}
					events={eventData}
					eventContent={(arg) => {
						// console.log("arg", arg);
						const { event } = arg;
						const { title } = event._def;
						const { color } = event._def.ui;
						const { time } = event._def.extendedProps;

						return (
							<div
								className="event-content"
								style={{
									backgroundColor: color,
									borderRadius: "5px",
									padding: "5px",
									color: "white",
								}}
							>
								{title}
								<br />
								{time}
							</div>
						);
					}}
					plugins={[
						dayGridPlugin,
						timeGridPlugin,
						interactionPlugin,
						multiMonthPlugin,
					]}
					headerToolbar={{
						left: "prev,today,next",
						center: "title",
						right: "dayGridMonth,timeGridWeek,timeGridDay",
					}}
					views={{
						dayGridMonth: {},
						timeGridWeek: {
							nowIndicator: true,
							slotMinTime: "06:00:00",
							slotMaxTime: "22:00:00",
						},
						timeGridDay: {
							nowIndicator: true,
							slotMinTime: "06:00:00",
							slotMaxTime: "22:00:00",
						},
					}}
					// customize the day header Day 1st etc.
					// dayHeaderContent={(args) => {
					// 	return moment(args.date).format("ddd Do");
					// }}
					// count events of the days
					eventDidMount={({ event }) =>
						moment(event.start).isSame(new Date(), "day") && eventCount++
					}
					datesSet={({ startStr, endStr, args }) => {
						document
							// highlight the current day, by adding a class to the header
							.querySelectorAll(".fc-col-header-cell")
							.forEach(
								(header) =>
									header.textContent === currentDay &&
									header.classList.add("current-day-header")
							);

						const start = new Date(startStr);

						document.querySelectorAll(".fc-day").forEach((cell, index) => {
							const date = new Date(
								start.getTime() + index * 24 * 60 * 60 * 1000
							);

							if (calendarRef.current) {
								const eventsOnThisDay = calendarRef.current
									.getApi()
									.getEvents()
									.filter((event) => event.start.getDate() === date.getDate());
								if (eventsOnThisDay.length > 0) {
									const badge = Object.assign(document.createElement("badge"), {
										className: "badge",
									});

									// Reset event count
									const countTextElement = cell.querySelector(".countText");
									const badgeElement = cell.querySelector(".badge");
									if (countTextElement) countTextElement.remove();
									if (badgeElement) badgeElement.remove();

									cell.append(badge);

									if (
										calendarRef.current.getApi().view.type !== "dayGridMonth"
									) {
										const countText = Object.assign(
											document.createElement("span"),
											{
												className: "countText",
												textContent: ` ${eventsOnThisDay.length} Schedule`,
											}
										);
										cell.append(countText);
									}
								}
							}
						});
					}}
					// switch calendar view when event is clicked
					eventClick={({ view, event }) => {
						const currentView = view.type;
						let nextView =
							currentView === "dayGridMonth"
								? "timeGridWeek"
								: currentView === "timeGridWeek"
								? "timeGridDay"
								: "dayGridMonth";
						view.calendar.changeView(nextView);
						view.calendar.gotoDate(event.start);
					}}
				/>
			</div>
		)
	);
}
