import {
    DayPilotCalendar,
    DayPilotNavigator,
} from "@daypilot/daypilot-lite-react";
import { useRef, useState } from "react";

export default function PageStudentCalendar() {
    const [config, setConfig] = useState({
        viewType: "Resources",
        headerHeight: 40,
        businessBeginsHour: 6,
        businessEndsHour: 23,
        height: 600,
        AutoScroll: false,
    });

    const columns = [
        { name: "Monday", id: "Mon" },
        { name: "Tuesday", id: "Tue" },
        { name: "Wednesday", id: "Wed" },
        { name: "Thursday", id: "Thu" },
        { name: "Friday", id: "Fri" },
        { name: "Saturday", id: "Sat" },
        { name: "Sunday", id: "Sun" },
    ];

    const calendarRef = useRef();

    return (
        <div className="calendar-wrapper">
            {/* <div>
                <DayPilotNavigator />
            </div> */}
            <div>
                <DayPilotCalendar
                    columns={columns}
                    {...config}
                    ref={calendarRef}
                />
            </div>
        </div>
    );
}
