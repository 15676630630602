import React, { useState } from "react";
import { Col, Row, Form, Avatar, List, Button } from "antd";
import Typography from "antd/es/typography/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import {
	faStar,
	faCircleInfo,
	faTrash,
} from "@fortawesome/pro-solid-svg-icons";

import VirtualList from "rc-virtual-list";

import FloatInputSearch from "../../../providers/FloatInputSearch";

export default function PageStudentMessages() {
	const [form] = Form.useForm();
	const logo = window.location.origin + "/assets/images/fsuu_logo.png";

	const [selectedMessage, setSelectedMessage] = useState("");

	const data = [
		{
			title: "FSUU Admin",
			email: "fsuu@example.com",
			picture: { large: logo },
		},
		{
			title: "User 1",
			email: "user1@example.com",
			picture: { large: "url_to_large_avatar" },
		},
		{
			title: "User 2",
			email: "user2@example.com",
			picture: { large: "url_to_large_avatar" },
		},
		{
			title: "User 3",
			email: "user3@example.com",
			picture: { large: "url_to_large_avatar" },
		},
		{
			title: "User 4",
			email: "user4@example.com",
			picture: { large: "url_to_large_avatar" },
		},
		{
			title: "User 5",
			email: "user5@example.com",
			picture: { large: "url_to_large_avatar" },
		},
	];

	const ContainerHeight = 400;

	const onScroll = (e) => {
		if (
			e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
			ContainerHeight
		) {
		}
	};

	const handleMessageClick = (item) => {
		setSelectedMessage(item.title);
		console.log(setSelectedMessage);
	};

	return (
		<Form form={form}>
			<Row gutter={[12, 0]} className="message-menu-container">
				<Col xs={24} sm={24} md={24} lg={24} className="searchbar-wrapper">
					<Form.Item name="search">
						<FloatInputSearch
							placeholder="Search"
							label="Search"
							allowClear
							prefix={
								<FontAwesomeIcon
									className="menu-submenu-notification"
									icon={faSearch}
								/>
							}
						></FloatInputSearch>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} className="message-list-wrapper">
					<List>
						<VirtualList
							data={data}
							height={ContainerHeight}
							itemHeight={47}
							itemKey="title"
							onScroll={onScroll}
						>
							{(item) => (
								<List.Item key={item.title}>
									<List.Item.Meta
										avatar={<Avatar src={item.picture.large} />}
										title={<a className="sender-name">{item.title}</a>}
										description={item.email}
									/>
									<div className="date-sent">8/09/2023</div>
								</List.Item>
							)}
						</VirtualList>
					</List>
				</Col>
			</Row>

			<Row gutter={[12, 0]} className="message-content-container">
				<Col xs={24} sm={24} md={24} lg={24} className="message-header-wrapper">
					<Col className="message-header-left">
						<img src={logo} alt="" />
						<Typography.Title>{selectedMessage}</Typography.Title>
					</Col>

					<Col className="message-header-right">
						<Button
							type="link"
							message-header-right="true"
							className="info-btn"
						>
							<FontAwesomeIcon icon={faCircleInfo} />
						</Button>
						<Button
							type="link"
							message-header-right="true"
							className="info-btn star"
						>
							<FontAwesomeIcon icon={faStar} />
						</Button>

						<Button
							type="link"
							message-header-right="true"
							className="info-btn star"
						>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					</Col>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					className="message-content-wrapper"
				>
					Messages Content
				</Col>
			</Row>
		</Form>
	);
}
