import React from "react";
import { Form, Row, Col, Collapse } from "antd";
import PageStudentCalendar from "./PageStudentCalendar";

export default function PageStudentSchedule() {
    const [form] = Form.useForm();

    const { Panel } = Collapse;

    const data = [
        {
            subjcode1: "BRIDG 001",
            subjcode2: "NSTP 1",
            subjcode3: "IT 170",
            desc1: "Bridging Subject 1 (Algebra, Trigo, Calculus 1)",
            desc2: "Civic Welfare Training Service 1",
            desc3: "Computer Fundamentals and Operations",
            lec1: "3",
            lec2: "3",
            lec3: "2",
            lab1: "0",
            lab2: "0",
            lab3: "3",
            unit1: "3",
            unit2: "3",
            unit3: "3",
            sched1: "T/F 10:30AM-12:00PM/10:30AM-12:00PM",
            sched2: "S 01:30PM-04:30PM",
            sched3: "M/TH 09:00AM-11:00AM/09:00AM-12:00PM",
        },
    ];

    return (
        <Form form={form}>
            <Row gutter={[12, 0]} className="schedule-container">
                <Col xs={24} sm={24} md={24} lg={18}>
                    <Collapse
                        defaultActiveKey={["0"]}
                        accordion
                        key={0}
                        expandIconPosition="end"
                        className="schedule-collapse"
                    >
                        {data.map((item, index) => (
                            <Panel
                                header={
                                    <Row gutter={12}>
                                        <Col
                                            span={1}
                                            className="collapse-item header-1"
                                        >
                                            <strong>Num.</strong>
                                        </Col>
                                        <Col
                                            span={4}
                                            className="collapse-item header-2"
                                        >
                                            <strong>Subject Code</strong>
                                        </Col>
                                        <Col
                                            span={8}
                                            className="collapse-item header-3"
                                        >
                                            <strong>Description</strong>
                                        </Col>
                                        <Col
                                            span={1}
                                            className="collapse-item header-4"
                                        >
                                            <strong>Lec</strong>
                                        </Col>
                                        <Col
                                            span={1}
                                            className="collapse-item header-5"
                                        >
                                            <strong>Lab</strong>
                                        </Col>
                                        <Col
                                            span={1}
                                            className="collapse-item header-6"
                                        >
                                            <strong>Units</strong>
                                        </Col>
                                        <Col
                                            span={6}
                                            className="collapse-item header-7"
                                        >
                                            <strong>Schedule</strong>
                                        </Col>
                                    </Row>
                                }
                                key={index}
                            >
                                <Row gutter={16}>
                                    <Col span={2}>
                                        <strong>1</strong>
                                    </Col>
                                    <Col span={4}>{item.subjcode1}</Col>
                                    <Col span={7}>{item.desc1}</Col>
                                    <Col span={1}>{item.lec1}</Col>
                                    <Col span={1}>{item.lab1}</Col>
                                    <Col span={2}>{item.unit1}</Col>
                                    <Col span={6}>{item.sched1}</Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={2}>
                                        <strong>2</strong>
                                    </Col>
                                    <Col span={4}>{item.subjcode2}</Col>
                                    <Col span={7}>{item.desc2}</Col>
                                    <Col span={1}>{item.lec2}</Col>
                                    <Col span={1}>{item.lab2}</Col>
                                    <Col span={2}>{item.unit2}</Col>
                                    <Col span={6}>{item.sched2}</Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={2}>
                                        <strong>3</strong>
                                    </Col>
                                    <Col span={4}>{item.subjcode3}</Col>
                                    <Col span={7}>{item.desc3}</Col>
                                    <Col span={1}>{item.lec3}</Col>
                                    <Col span={1}>{item.lab3}</Col>
                                    <Col span={2}>{item.unit3}</Col>
                                    <Col span={6}>{item.sched3}</Col>
                                </Row>
                            </Panel>
                        ))}
                    </Collapse>
                </Col>
            </Row>

            <Row gutter={[12, 0]} className="calendar-container">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <PageStudentCalendar />
                </Col>
            </Row>
        </Form>
    );
}
