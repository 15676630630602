const optionDepartment = [
	{ value: "Accountancy Program", label: "Accountancy Program" },
	{ value: "Arts and Science Program", label: "Arts and Science Program" },
	{
		value: "Business Administration Program",
		label: "Business Administration Program",
	},
	{ value: "Computer Studies Program", label: "Computer Studies Program" },
	{ value: "College of Law", label: "College of Law" },
	{
		value: "Criminal Justice and Education Program",
		label: "Criminal Justice and Education Program",
	},
	{
		value: "Engineering and Technology Program",
		label: "Engineering and Technology Program",
	},
	{ value: "Graduate Studies", label: "Graduate Studies" },
	{ value: "Nursing Program", label: "Nursing Program" },
	{
		value: "Office of the Religious Affairs",
		label: "Office of the Religious Affairs",
	},
	{ value: "Senior High School", label: "Senior High School" },
	{ value: "Teacher Education Program", label: "Teacher Education Program" },
];
export default optionDepartment;
