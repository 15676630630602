import { useEffect, useRef, useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Pagination, Select, Typography } from "antd";
import optionAlphabet from "./optionAlphabet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleLeft,
	faAngleRight,
	faFilterList,
	faSearch,
} from "@fortawesome/pro-regular-svg-icons";

export function TablePagination(props) {
	const {
		showLessItems,
		showSizeChanger,
		tableFilter,
		setTableFilter,
		setPaginationTotal,
		tblIdWrapper,
	} = props;

	const [paginationSize, setPaginationSize] = useState("default");

	useEffect(() => {
		window.onresize = function () {
			if (window.offsetWidth <= 768) {
				setPaginationSize("small");
			} else {
				setPaginationSize("default");
			}
		};
	}, []);

	return (
		<Pagination
			current={tableFilter.page}
			total={setPaginationTotal}
			size={paginationSize}
			showLessItems={showLessItems ?? false}
			showSizeChanger={showSizeChanger ?? true}
			showTotal={(total, range) => {
				let tbl_wrapper = document.getElementById(tblIdWrapper);
				if (tbl_wrapper) {
					let span_page_from = tbl_wrapper.querySelector(".span_page_from");
					let span_page_to = tbl_wrapper.querySelector(".span_page_to");
					let span_page_total = tbl_wrapper.querySelector(".span_page_total");
					if (span_page_from) {
						span_page_from.textContent = range[0];
					}
					if (span_page_to) {
						span_page_to.textContent = range[1];
					}
					if (span_page_total) {
						span_page_total.textContent = total;
					}
				} else {
					let span_page_from = document.querySelector(".span_page_from");
					let span_page_to = document.querySelector(".span_page_to");
					let span_page_total = document.querySelector(".span_page_total");
					if (span_page_from) {
						span_page_from.textContent = range[0];
					}
					if (span_page_to) {
						span_page_to.textContent = range[1];
					}
					if (span_page_total) {
						span_page_total.textContent = total;
					}
				}
			}}
			pageSize={tableFilter.page_size}
			onChange={(page, pageSize) =>
				setTableFilter({
					...tableFilter,
					page,
					page_size: pageSize,
				})
			}
			itemRender={(current, type, originalElement) => {
				if (type === "prev") {
					return (
						<Button>
							<FontAwesomeIcon icon={faAngleLeft} />
						</Button>
					);
				}
				if (type === "next") {
					return (
						<Button>
							<FontAwesomeIcon icon={faAngleRight} />
						</Button>
					);
				}
				return originalElement;
			}}
		/>
	);
}

export function TableShowingEntries() {
	return (
		<Typography.Text>
			Showing <span className="span_page_from"></span> to{" "}
			<span className="span_page_to"></span> of{" "}
			<span className="span_page_total"></span> entries
		</Typography.Text>
	);
}

export function TablePageSize(props) {
	const { tableFilter, setTableFilter, className, option } = props;

	return (
		<div className="tbl-page-size">
			<Select
				value={tableFilter.page_size}
				onChange={(e) => setTableFilter({ ...tableFilter, page_size: e })}
				className={className ?? "ant-select-table-pagesize"}
				suffixIcon={<CaretDownOutlined />}
				size="large"
			>
				{option && option.length > 0 ? (
					option.map((item, index) => {
						return (
							<Select.Option value={item} key={index}>
								{item}
							</Select.Option>
						);
					})
				) : (
					<>
						<Select.Option value={10}>10 / Page</Select.Option>
						<Select.Option value={25}>25 / Page</Select.Option>
						<Select.Option value={50}>50 / Page</Select.Option>
						<Select.Option value={75}>75 / Page</Select.Option>
						<Select.Option value={100}>100 / Page</Select.Option>
					</>
				)}
			</Select>
		</div>
	);
}

export function TableGlobalSearchAnimated(props) {
	const {
		tableFilter,
		setTableFilter,
		placeholder,
		size,
		className,
		wrapClassName,
	} = props;

	const [searchTextTimeout, setSearchTextTimeout] = useState(0);
	const inputRef = useRef(null);
	const [toggleShow, setToggleShow] = useState(false);

	useEffect(() => {
		if (toggleShow && inputRef.current) {
			inputRef.current.focus();
		}
	}, [toggleShow]);

	return (
		<div
			className={`tbl-search-wrapper animated${
				wrapClassName ? " " + wrapClassName : ""
			}${toggleShow ? " show" : ""}`}
		>
			<Input
				ref={inputRef}
				allowClear
				suffix={
					<FontAwesomeIcon
						icon={faSearch}
						onClick={() => {
							setToggleShow(!toggleShow);
						}}
					/>
				}
				placeholder={placeholder ?? "Search..."}
				size={size ?? "middle"}
				className={className ?? "ant-input-padding-inherit"}
				onChange={(e) => {
					if (searchTextTimeout) clearTimeout(searchTextTimeout);
					clearTimeout(searchTextTimeout);
					let timeoutTemp = setTimeout(() => {
						setTableFilter({
							...tableFilter,
							search: e.target.value,
							page: 1,
						});
					}, 1000);
					setSearchTextTimeout(timeoutTemp);
				}}
			/>
		</div>
	);
}

export function TableGlobalSearch(props) {
	const { tableFilter, setTableFilter, placeholder, size, className } = props;

	const [searchTextTimeout, setSearchTextTimeout] = useState(0);

	return (
		<div className="tbl-search-wrapper">
			<Input
				suffix={<FontAwesomeIcon icon={faSearch} />}
				placeholder={placeholder ?? "Search..."}
				size={size ?? "large"}
				className={className ?? "ant-input-padding-inherit"}
				onChange={(e) => {
					if (searchTextTimeout) clearTimeout(searchTextTimeout);
					clearTimeout(searchTextTimeout);
					let timeoutTemp = setTimeout(() => {
						setTableFilter({
							...tableFilter,
							search: e.target.value,
							page: 1,
						});
					}, 1000);
					setSearchTextTimeout(timeoutTemp);
				}}
				allowClear
			/>
		</div>
	);
}

export function TableGlobalAlphaSearch(props) {
	const { tableFilter, setTableFilter, size, className } = props;
	const [active, setActive] = useState("");

	return (
		<div className={"flex table-filter-alphabet " + (className ?? "")}>
			{optionAlphabet.map((item, index) => (
				<Button
					key={index}
					type="link"
					size={size ?? "large"}
					onClick={() => {
						setTableFilter({
							...tableFilter,
							letter: item,
							page: 1,
						});
						setActive(item);
					}}
					className={active === item ? "btn-main-outline" : ""}
				>
					{item}
				</Button>
			))}
		</div>
	);
}

export const useTableScrollOnTop = (id, location = "") => {
	useEffect(() => {
		const tbl1 = document.getElementById(id);
		const tableStickyHolder = tbl1.querySelector(
			".ant-table-header.ant-table-sticky-holder"
		);
		const tableBody = tbl1.querySelector(".ant-table-body");

		const handleScroll = (event) => {
			const { scrollLeft, scrollTop } = event.target;
			if (tableStickyHolder) {
				tableStickyHolder.scrollLeft = scrollLeft;
				tableStickyHolder.scrollTop = scrollTop;
			}
			if (tableBody) {
				tableBody.scrollLeft = scrollLeft;
				tableBody.scrollTop = scrollTop;
			}
		};

		if (tableStickyHolder) {
			tableStickyHolder.addEventListener("scroll", handleScroll);
		}
		if (tableBody) {
			tableBody.addEventListener("scroll", handleScroll);
		}

		// Clean up event listeners when the component unmounts
		return () => {
			if (tableStickyHolder) {
				tableStickyHolder.removeEventListener("scroll", handleScroll);
			}
			if (tableBody) {
				tableBody.removeEventListener("scroll", handleScroll);
			}
		};
	}, [id, location]);

	return "";
};

export const TableDropdownFilter = ({ items }) => {
	const [openFilter, setOpenFilter] = useState(false);

	return (
		<Dropdown
			menu={{
				items,
			}}
			placement="bottomLeft"
			align={{
				offset: [0, 0],
			}}
			open={openFilter}
			onOpenChange={(nextOpen, info) => {
				if (info.source === "trigger" || nextOpen) {
					setOpenFilter(nextOpen);
				}
			}}
			overlayClassName="tbl-dropdown-filter"
			dropdownAlign={{ offset: [0, 0] }}
		>
			<Button
				className="tbl-btn-dropdown-filter"
				icon={<FontAwesomeIcon icon={faFilterList} />}
			>
				Filter
			</Button>
		</Dropdown>
	);
};
