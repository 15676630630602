import { Form, Row, Col, Typography, Button, Descriptions } from "antd";
import React from "react";

export default function PageStudentGrade() {
    const [form] = Form.useForm();

    const header = [
        {
            key: "1",
            label: "Admission Status ",
            children: "OLD",
        },
        {
            key: "2",
            label: "Scholastic Status",
            children: "REGULAR",
        },
        {
            key: "3",
            label: "",
            children: "",
        },

        { key: "4", label: "Course Code", children: "BSIT" },
        {
            key: "5",
            label: "Course Description",
            children: "BACHELOR OF SCIENCE IN INFORMATION TECHNOLOGY",
        },
    ];

    const data = [
        {
            // Panel
            col1: "#",
            col2: "Subject Code",
            col3: "Description",
            col4: "Faculty Name",
            col5: "Units",
            col6: "Section",
            col7: "Midterm",
            col8: "Final",
            col9: "Final Grade",
            col10: "Grade Status",

            // Data
            subjcode1: "BRIDG 001",
            subjcode2: "NSTP 1",
            subjcode3: "IT 170",
            desc1: "Bridging Subject 1 (Algebra, Trigo, Calculus 1)",
            desc2: "Civic Welfare Training Service 1",
            desc3: "Computer Fundamentals and Operations",
            faculty1: "BORBON, MELQUIZEDEK GONZALES",
            faculty2: "VALENCIA, ROCHELLE ANTIPORTA",
            faculty3: "GUIRITAN, DOMINIC REOTOTAR",
            unit1: "3",
            unit2: "3",
            unit3: "3",
            sec1: "IT-11",
            sec2: "IT-11",
            sec3: "IT-11",
            mid1: "2",
            mid2: "1",
            mid3: "3",
            final1: "1.75",
            final2: "1.5",
            final3: "3",
            status1: "PASSED",
            status2: "PASSED",
            status3: "PASSED",
        },
    ];

    return (
        <Form form={form}>
            <Row gutter={[12, 0]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="student-profile-image"
                >
                    <Col className="student-info-container">
                        <Typography.Title>
                            Last Name, First Name
                        </Typography.Title>
                        <Typography className="studentID-wrapper">
                            Student ID: 20203815A
                        </Typography>
                    </Col>
                    <Button className="btn-evaluate mr-20 ">
                        Curriculum/Evaluation
                    </Button>
                    <Button className="btn-grade-slip ">
                        Advanced Grade Slip
                    </Button>
                </Col>
            </Row>

            <Row gutter={[12, 0]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="student-grade-container mt-30"
                >
                    <Col className="grade-header">
                        <Descriptions items={header} />
                    </Col>

                    <Col className="grade-subheader">
                        <Col>School Year: 2023</Col>
                        <Col>Sem: First</Col>
                        <Col>GPA: 1.70</Col>
                    </Col>

                    <Col className="grade-wrapper">
                        {data.map((item, index) => (
                            <div key={index}>
                                <Row
                                    gutter={24}
                                    className="grade-panel-wrapper"
                                >
                                    <Col span={2}>{item.col1}</Col>
                                    <Col span={2}>{item.col2}</Col>
                                    <Col span={7}>{item.col3}</Col>
                                    <Col span={5}>{item.col4}</Col>
                                    <Col span={1}>{item.col5}</Col>
                                    <Col span={1}>{item.col6}</Col>
                                    <Col span={1}>{item.col7}</Col>
                                    <Col span={2}>{item.col8}</Col>
                                    <Col span={1}>{item.col9}</Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 1}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode1}</Col>
                                    <Col span={7}>{item.desc1}</Col>
                                    <Col span={5}>{item.faculty1}</Col>
                                    <Col span={1}>{item.unit1}</Col>
                                    <Col span={1}>{item.sec1}</Col>
                                    <Col span={1}>{item.mid1}</Col>
                                    <Col span={2}>{item.final1}</Col>
                                    <Col span={1}>{item.status1}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 2}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode2}</Col>
                                    <Col span={7}>{item.desc2}</Col>
                                    <Col span={5}>{item.faculty2}</Col>
                                    <Col span={1}>{item.unit2}</Col>
                                    <Col span={1}>{item.sec2}</Col>
                                    <Col span={1}>{item.mid2}</Col>
                                    <Col span={2}>{item.final2}</Col>
                                    <Col span={1}>{item.status2}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 4}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode3}</Col>
                                    <Col span={7}>{item.desc3}</Col>
                                    <Col span={5}>{item.faculty3}</Col>
                                    <Col span={1}>{item.unit3}</Col>
                                    <Col span={1}>{item.sec3}</Col>
                                    <Col span={1}>{item.mid3}</Col>
                                    <Col span={2}>{item.final3}</Col>
                                    <Col span={1}>{item.status3}</Col>
                                </Row>
                            </div>
                        ))}
                    </Col>
                </Col>
            </Row>

            <Row gutter={[12, 0]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="student-grade-container mt-30"
                >
                    <Col className="grade-header mb-5">
                        <Descriptions items={header} />
                    </Col>

                    <Col className="grade-subheader">
                        <Col>School Year: 2023</Col>
                        <Col>Sem: First</Col>
                        <Col>GPA: 1.70</Col>
                    </Col>

                    <Col className="grade-wrapper">
                        {data.map((item, index) => (
                            <div key={index}>
                                <Row
                                    gutter={24}
                                    className="grade-panel-wrapper"
                                >
                                    <Col span={2}>{item.col1}</Col>
                                    <Col span={2}>{item.col2}</Col>
                                    <Col span={7}>{item.col3}</Col>
                                    <Col span={5}>{item.col4}</Col>
                                    <Col span={1}>{item.col5}</Col>
                                    <Col span={1}>{item.col6}</Col>
                                    <Col span={1}>{item.col7}</Col>
                                    <Col span={2}>{item.col8}</Col>
                                    <Col span={1}>{item.col9}</Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 1}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode1}</Col>
                                    <Col span={7}>{item.desc1}</Col>
                                    <Col span={5}>{item.faculty1}</Col>
                                    <Col span={1}>{item.unit1}</Col>
                                    <Col span={1}>{item.sec1}</Col>
                                    <Col span={1}>{item.mid1}</Col>
                                    <Col span={2}>{item.final1}</Col>
                                    <Col span={1}>{item.status1}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 2}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode2}</Col>
                                    <Col span={7}>{item.desc2}</Col>
                                    <Col span={5}>{item.faculty2}</Col>
                                    <Col span={1}>{item.unit2}</Col>
                                    <Col span={1}>{item.sec2}</Col>
                                    <Col span={1}>{item.mid2}</Col>
                                    <Col span={2}>{item.final2}</Col>
                                    <Col span={1}>{item.status2}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 3}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode3}</Col>
                                    <Col span={7}>{item.desc3}</Col>
                                    <Col span={5}>{item.faculty3}</Col>
                                    <Col span={1}>{item.unit3}</Col>
                                    <Col span={1}>{item.sec3}</Col>
                                    <Col span={1}>{item.mid3}</Col>
                                    <Col span={2}>{item.final3}</Col>
                                    <Col span={1}>{item.status3}</Col>
                                </Row>
                            </div>
                        ))}
                    </Col>
                </Col>
            </Row>

            <Row gutter={[12, 0]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="student-grade-container mt-30"
                >
                    <Col className="grade-header mb-5">
                        <Descriptions items={header} />
                    </Col>

                    <Col className="grade-subheader">
                        <Col>School Year: 2023</Col>
                        <Col>Sem: First</Col>
                        <Col>GPA: 1.70</Col>
                    </Col>

                    <Col className="grade-wrapper">
                        {data.map((item, index) => (
                            <div key={index}>
                                <Row
                                    gutter={24}
                                    className="grade-panel-wrapper"
                                >
                                    <Col span={2}>{item.col1}</Col>
                                    <Col span={2}>{item.col2}</Col>
                                    <Col span={7}>{item.col3}</Col>
                                    <Col span={5}>{item.col4}</Col>
                                    <Col span={1}>{item.col5}</Col>
                                    <Col span={1}>{item.col6}</Col>
                                    <Col span={1}>{item.col7}</Col>
                                    <Col span={2}>{item.col8}</Col>
                                    <Col span={1}>{item.col9}</Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 1}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode1}</Col>
                                    <Col span={7}>{item.desc1}</Col>
                                    <Col span={5}>{item.faculty1}</Col>
                                    <Col span={1}>{item.unit1}</Col>
                                    <Col span={1}>{item.sec1}</Col>
                                    <Col span={1}>{item.mid1}</Col>
                                    <Col span={2}>{item.final1}</Col>
                                    <Col span={1}>{item.status1}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 2}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode2}</Col>
                                    <Col span={7}>{item.desc2}</Col>
                                    <Col span={5}>{item.faculty2}</Col>
                                    <Col span={1}>{item.unit2}</Col>
                                    <Col span={1}>{item.sec2}</Col>
                                    <Col span={1}>{item.mid2}</Col>
                                    <Col span={2}>{item.final2}</Col>
                                    <Col span={1}>{item.status2}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={2}>
                                        <strong>{index + 3}</strong>
                                    </Col>
                                    <Col span={2}>{item.subjcode3}</Col>
                                    <Col span={7}>{item.desc3}</Col>
                                    <Col span={5}>{item.faculty3}</Col>
                                    <Col span={1}>{item.unit3}</Col>
                                    <Col span={1}>{item.sec3}</Col>
                                    <Col span={1}>{item.mid3}</Col>
                                    <Col span={2}>{item.final3}</Col>
                                    <Col span={1}>{item.status3}</Col>
                                </Row>
                            </div>
                        ))}
                    </Col>
                </Col>
            </Row>
        </Form>
    );
}
