import React from "react";
import { Descriptions, Typography } from "antd";
import { Row, Col } from "antd/lib";

export default function PageStudentRegistration() {
    const items = [
        {
            key: "1",
            label: "Student Name",
            span: 2,
            children: "LAST NAME, FIST NAME",
        },
        {
            key: "2",
            span: 1,
            label: "Student No.",
            children: "19100000832",
        },
        {
            key: "3",
            label: "Course",
            span: 2,
            children: "BACHELOR OF SCIENCE IN INFORMATION TECHNOLOGY",
        },
        {
            key: "4",
            span: 1,
            label: "Year Level",
            children: "Fourth Year",
        },
        {
            key: "5",
            label: "Status",
            span: 2,
            children: "OLD (Regular) (15 Unit(s) Allowed)",
        },
        {
            key: "6",
            span: 1,
            label: "Section",
            children: "IT13",
        },
        {
            key: "7",
            span: 2,
            label: "School Year",
            children: "2023-2024",
        },
        {
            key: "8",
            span: 1,
            label: "Semester",
            children: "First",
        },
    ];

    return (
        <Row gutter={[12, 12]}>
            <Col sm={24} md={24} lg={18}>
                <Descriptions items={items} />
            </Col>
            <Col sm={24} md={24} lg={18}>
                <Typography.Title level={1}>
                    Registration is not yet open.
                </Typography.Title>
            </Col>
        </Row>
    );
}
