import { useLocation } from "react-router-dom";
import { Row, Button, Col, Tabs } from "antd";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

import { GET } from "../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faList, faPlus } from "@fortawesome/pro-regular-svg-icons";

import TableEntranceExam from "./TableEntranceExam";
import PageEntranceExamCalendar from "./PageEntranceExamCalendar";
import ModalFormEntranceExamSchedule from "../PageEntranceExam/components/ModalFormEntranceExamSchedule";

export default function PageEntranceExam() {
	const location = useLocation();

	moment.tz.setDefault("Asia/Manila");

	const [
		toggleModalFormEntranceExamSchedule,
		setToggleModalFormEntranceExamSchedule,
	] = useState({
		open: false,
		data: null,
	});

	moment.tz.setDefault("Asia/Manila");

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status: "Active",
	});

	const {
		data: dataExamScheduleSource,
		refetch: refetchSource,
		isLoading: isLoadingDataExamScheduleSource,
		isFetching: isFetchingDataExamScheduleSource,
	} = GET(
		`api/ref_exam_schedule?${new URLSearchParams(tableFilter)}`,
		"ref_exam_schedule_list"
	);

	const [activeTab, setActiveTab] = useState("1");

	useEffect(() => {
		refetchSource(dataExamScheduleSource);

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter, activeTab]);

	return (
		<>
			<Tabs
				className="TABS"
				type="card"
				defaultActiveKey="1"
				onChange={(e) => {
					if (
						!isLoadingDataExamScheduleSource ||
						!isFetchingDataExamScheduleSource
					)
						setActiveTab(e);
				}}
				items={[
					{
						icon: <FontAwesomeIcon icon={faList} />,
						label: "Exam Schedule List View",
						key: "1",
						children: (
							<Row gutter={[12, 12]}>
								{location.pathname === "/entrance-exam/schedule" ? (
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Button
											className=" btn-main-primary btn-main-invert-outline b-r-none"
											onClick={() =>
												setToggleModalFormEntranceExamSchedule({
													open: true,
												})
											}
											icon={<FontAwesomeIcon icon={faPlus} />}
											size="large"
										>
											Add Exam Schedule
										</Button>
									</Col>
								) : null}

								<Col xs={24} sm={24} md={24}>
									<TableEntranceExam
										dataSource={dataExamScheduleSource}
										tableFilter={tableFilter}
										setTableFilter={setTableFilter}
										location={location}
										refetchSource={refetchSource}
									/>
								</Col>
							</Row>
						),
					},
					{
						icon: <FontAwesomeIcon icon={faCalendar} />,
						label: "Exam Schedule Calendar View",
						className: "tab-item-school-attended-info",
						key: "2",
						disabled:
							!dataExamScheduleSource || dataExamScheduleSource.length === 0,
						children: (
							<PageEntranceExamCalendar
								dataSource={dataExamScheduleSource}
								location={location}
								refetchSource={refetchSource}
							/>
						),
					},
				]}
			/>

			<ModalFormEntranceExamSchedule
				toggleModalFormEntranceExamSchedule={
					toggleModalFormEntranceExamSchedule
				}
				setToggleModalFormEntranceExamSchedule={
					setToggleModalFormEntranceExamSchedule
				}
				dataSource={dataExamScheduleSource}
				setTableFilter={setTableFilter}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				tableFilter={tableFilter}
				refetchSource={refetchSource}
				moment={moment}
			/>
		</>
	);
}
