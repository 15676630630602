import { Routes, Route } from "react-router-dom";
import {
	faComment,
	faUsers,
	faCheckSquare,
	faClipboard,
	faCalendar,
	faFilePlus,
	faClipboardCheck,
	faSquareUser,
	faHome,
	faFileZipper,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PageLogin from "../views/public/PageLogin/PageLogin";
import PageRegister from "../views/public/PageRegister/PageRegister";
import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";

import PageStudentDashboard from "../views/private/PageStudentDashboard/PageStudentDashboard";
import PageStudentMessages from "../views/private/PageStudentMessages/PageStudentMessages";
import PageStudentSections from "../views/private/PageStudentSections/PageStudentSections";
import PageStudentRegistration from "../views/private/PageStudentRegistration/PageStudentRegistration";
import PageStudentProfile from "../views/private/PageStudentProfile/PageStudentProfile";
import PageStudentSchedule from "../views/private/PageStudentSchedule/PageStudentSchedule";
import PageStudentGrade from "../views/private/PageStudentGrade/PageStudentGrade";
import PageStudentAccount from "../views/private/PageStudentAccount/PageStudentAccount";
import PageEntranceExam from "../views/private/PageEntranceExam/PageEntranceExam";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/registration/:id"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageRegistration"
						component={PageRegister}
					/>
				}
			/>

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="Student"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						component={PageEditProfile}
					/>
				}
			/>

			{/* STUDENT START*/}
			<Route
				path="/student-dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="Student"
						pageId="PageStudentDashboard"
						pageHeaderIcon={faHome}
						component={PageStudentDashboard}
					/>
				}
			/>

			<Route
				path="/student-messages"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Messages"
						title="Messages"
						subtitle="Student"
						pageId="PageStudentMessages"
						pageHeaderIcon={faComment}
						component={PageStudentMessages}
					/>
				}
			/>

			<Route
				path="/student-sections"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Section Offering"
						title="Section Offering"
						subtitle="Student"
						pageId="PageStudentSections"
						pageHeaderIcon={faCheckSquare}
						component={PageStudentSections}
					/>
				}
			/>

			<Route
				path="/student-registration"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Registration"
						title="Registration"
						subtitle="Student"
						pageId="PageStudentRegistration"
						pageHeaderIcon={faClipboard}
						component={PageStudentRegistration}
					/>
				}
			/>

			<Route
				path="/student-profile"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Profile"
						title="Profile"
						subtitle="Student"
						pageId="PageStudentProfile"
						pageHeaderIcon={faSquareUser}
						component={PageStudentProfile}
					/>
				}
			/>

			<Route
				path="/student-schedule"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Schedule"
						title="Schedule"
						subtitle="Student"
						pageId="PageStudentSchedule"
						pageHeaderIcon={faCalendar}
						component={PageStudentSchedule}
					/>
				}
			/>

			<Route
				path="/student-grades"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Grade"
						title="Grade"
						subtitle="Student"
						pageId="PageStudentGrade"
						pageHeaderIcon={faFilePlus}
						component={PageStudentGrade}
					/>
				}
			/>

			<Route
				path="/student-account"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Account"
						title="Account"
						subtitle="Student"
						pageId="PageStudentAccount"
						pageHeaderIcon={faClipboardCheck}
						component={PageStudentAccount}
					/>
				}
			/>

			{/* ENTRANCE EXAM START */}
			<Route
				path="/student-entrance-exam"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Entrance Exam"
						title="Entrance Exam"
						subtitle="Student"
						pageId="PageStudentEntranceExam"
						pageHeaderIcon={faFileZipper}
						component={PageEntranceExam}
					/>
				}
			/>
			{/* ENTRANCE EXAM END*/}

			<Route
				path="/student-entrance-exam/schedule"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Account"
						title="Account"
						subtitle="Student"
						pageId="PageStudentAccount"
						pageHeaderIcon={faClipboardCheck}
						component={PageStudentAccount}
					/>
				}
			/>

			<Route
				path="/student-entrance-exam/results"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Account"
						title="Account"
						subtitle="Student"
						pageId="PageStudentAccount"
						pageHeaderIcon={faClipboardCheck}
						component={PageStudentAccount}
					/>
				}
			/>
			{/* STUDENT END*/}

			{/* <Route
                path="/student-settings"
                element={
                    <PrivateRoute
                        moduleCode="M-01"
                        moduleName="Setting"
                        title="Setting"
                        subtitle="Student"
                        pageId="PageStudentSettings"
                        pageHeaderIcon={faGear}
                    >
                        <PageStudentSettings />
                    </PrivateRoute>
                }
            /> */}

			{/* STUDENT END*/}
		</Routes>
	);
}
