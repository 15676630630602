import CryptoJS from "crypto-js";

export const name = process.env.REACT_APP_NAME;
export const description = process.env.REACT_APP_DESCRIPTION;
export const bgColor = process.env.REACT_APP_BG_COLOR;
export const logo = process.env.REACT_APP_LOGO;
export const logoFullWidth = process.env.REACT_APP_LOGO_FULLWIDTH;
export const apiUrl = (url) => `${process.env.REACT_APP_API_URL}/${url}`;

export const defaultProfile = apiUrl("images/default.png");
export const defaultDocument = apiUrl("images/documents.png");

export const date = new Date();

export const encryptKey =
	process.env.REACT_ENCRYPT_APP_KEY + `-${date.getFullYear()}`;

export const encrypt = (data) => {
	return CryptoJS.AES.encrypt(data, encryptKey).toString();
};
export const decrypt = (data) => {
	return CryptoJS.AES.decrypt(data, encryptKey).toString(CryptoJS.enc.Utf8);
};

export const token = () => {
	if (localStorage.token === null) {
		localStorage.token = "";
		localStorage.userData = "";
		return false;
	}
	return "Bearer " + localStorage.token;
};

export const userData = () => {
	if (localStorage.userdata === null) {
		localStorage.token = "";
		localStorage.userdata = "";
		return false;
	}
	return JSON.parse(decrypt(localStorage.userdata));
};

export const role = () => {
	if (localStorage.userdata === null) {
		localStorage.token = "";
		localStorage.userdata = "";
		return false;
	}
	return JSON.parse(decrypt(localStorage.userdata)).role;
};
