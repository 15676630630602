import React from "react";
import { Col, Row, Form, Button } from "antd";

import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";

export default function PageStudentSections() {
	const [form] = Form.useForm();
	const logo = window.location.origin + "/assets/images/fsuu_logo.png";

	return (
		<Form form={form} layout="vertical">
			<Row gutter={[12, 12]}>
				<Col sm={24} md={24} lg={14} xl={14} xxl={14} className="logo-wrapper ">
					<img src={logo} />
				</Col>

				<Col
					sm={24}
					md={24}
					lg={14}
					xl={14}
					xxl={14}
					className="subject-search-container"
				>
					<Form.Item className="custom-label-style" label="Search Subject">
						<FloatInput
							className="search-wrapper"
							label="search"
							placeholder="Subject"
						></FloatInput>

						<Button size="large" type="submit" className="search-btn-wrapper">
							Search
						</Button>
					</Form.Item>
				</Col>

				<Col
					sm={24}
					md={24}
					lg={14}
					xl={14}
					xxl={14}
					className="course-container"
				>
					<Form.Item>
						<FloatSelect
							className="course-wrapper"
							label="Select Course"
							placeholder="Select Course"
							options={[
								{
									label: "AP",
									value: "Ap",
								},
								{
									label: "ASP",
									value: "ASP",
								},
								{
									label: "BAP",
									value: "BAP",
								},
								{
									label: "CJEP",
									value: "CJEP",
								},
								{
									label: "CSP",
									value: "CSP",
								},
								{
									label: "ETP",
									value: "ETP",
								},
								{
									label: "Nursing",
									value: "Nursing",
								},
							]}
						></FloatSelect>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
