import { Form, Row, Col, Typography, Button, Descriptions } from "antd";
import React from "react";

export default function PageStudentAccount() {
    const [form] = Form.useForm();

    const header = [
        {
            key: "1",
            label: "Sem",
            children: "First",
        },
        {
            key: "2",
            label: "Year Level",
            children: "2",
        },
    ];

    const data = [
        {
            // Panel
            col1: "Scholarship",
            col2: "O.R. Date",
            col3: "O.R. No.",
            col4: "Assessment",
            col5: "Payment",
            col6: "Balance",

            // Data
            scholar1: "",
            ordate1: "00/00/000",
            orno1: "TOTAL AMOUNT DUE",
            assessment1: "25,672.75",
            payment1: "0.00",
            balance1: "25,672.75",

            scholar2: "TESDA-DIPLOMA PROGRAM 1920",
            ordate2: "05/21/2019",
            orno2: "CASH - Initial Payment (607878)",
            assessment2: "0.00",
            payment2: "1,500.00",
            balance2: "24,172.75",

            scholar3: "",
            ordate3: "06/27/2019",
            orno3: "CASH - PRELIM (619763)",
            assessment3: "0.00",
            payment3: "11,050.00",
            balance3: "13,122.75",
        },
    ];

    return (
        <Form form={form}>
            <Row gutter={[12, 0]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="student-profile-image"
                >
                    <Col className="student-info-container">
                        <Typography.Title>
                            Last Name, First Name
                        </Typography.Title>
                        <Typography className="studentID-wrapper">
                            Student ID: 20203815A
                        </Typography>
                    </Col>
                    <Button className="btn-assessment mr-20 ">
                        Assessment Fees
                    </Button>
                    <Button className="btn-non-assessment ">
                        Non-Assessment Fees
                    </Button>
                </Col>
            </Row>

            <Row gutter={[12, 0]} className="w-90 ml-90">
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="student-fees-container mt-30"
                >
                    <Col className="fees-header mb-5">
                        <Descriptions items={header} />
                    </Col>

                    <Col className="fees-wrapper">
                        {data.map((item, index) => (
                            <div key={index}>
                                <Row gutter={24} className="fees-panel-wrapper">
                                    <Col span={4}>{item.col1}</Col>
                                    <Col span={4}>{item.col2}</Col>
                                    <Col span={5}>{item.col3}</Col>
                                    <Col span={3}>{item.col4}</Col>
                                    <Col span={4}>{item.col5}</Col>
                                    <Col span={4}>{item.col6}</Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={4}>{item.scholar1}</Col>
                                    <Col span={4}>{item.ordate1}</Col>
                                    <Col span={5}>{item.orno1}</Col>
                                    <Col span={3}>{item.assessment1}</Col>
                                    <Col span={4}>{item.payment1}</Col>
                                    <Col span={4}>{item.balance1}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={4}>{item.scholar2}</Col>
                                    <Col span={4}>{item.ordate2}</Col>
                                    <Col span={5}>{item.orno2}</Col>
                                    <Col span={3}>{item.assessment2}</Col>
                                    <Col span={4}>{item.payment2}</Col>
                                    <Col span={4}>{item.balance2}</Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={4}>{item.scholar3}</Col>
                                    <Col span={4}>{item.ordate3}</Col>
                                    <Col span={5}>{item.orno3}</Col>
                                    <Col span={3}>{item.assessment3}</Col>
                                    <Col span={4}>{item.payment3}</Col>
                                    <Col span={4}>{item.balance3}</Col>
                                </Row>
                            </div>
                        ))}
                    </Col>
                </Col>
            </Row>
        </Form>
    );
}
