import { Row, Col, Table } from "antd";
import { useState } from "react";

import moment from "moment";

import ModalFormEntranceExamSchedule from "./components/ModalFormEntranceExamSchedule";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntries,
} from "../../../providers/CustomTableFilter";

export default function TableEntranceExam(props) {
	const { dataSource, setTableFilter, tableFilter } = props;

	const [
		toggleModalFormEntranceExamSchedule,
		setToggleModalFormEntranceExamSchedule,
	] = useState({
		open: false,
		data: null,
	});

	console.log("tableFiltersss: ", tableFilter.status);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	return (
		<>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24}>
					<div className="tbl-top-filter">
						<TablePageSize
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
						<TableGlobalSearch
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<Table
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						onChange={onChangeTable}
						scroll={{ x: "max-content" }}
					>
						<Table.Column
							title="School Year"
							key="school_year"
							dataIndex="school_year"
							sorter={(a, b) =>
								moment(a.sy_from).unix() - moment(b.sy_to).unix()
							}
							render={(text, record) => {
								return (
									<span>
										{record.sy_from} - {record.sy_to}
									</span>
								);
							}}
						/>

						<Table.Column
							title="Semester"
							key="semester_id"
							dataIndex="semester_id"
							sorter
							render={(text, record) => {
								return <span>{record.semester}</span>;
							}}
						/>

						<Table.Column
							title="Exam Date"
							key="exam_date"
							dataIndex="exam_date"
							sorter={(a, b) =>
								moment(a.exam_date).unix() - moment(b.exam_date).unix()
							}
							render={(exam_date) => moment(exam_date).format("MMMM DD, YYYY")}
						/>

						<Table.Column
							title="Exam Time"
							key="exam_time"
							dataIndex="exam_time"
							sorter={true}
							render={(text, record) => {
								return (
									<span>
										{record.time_in} {record.time_in_meridiem} -{" "}
										{record.time_out} {record.time_out_meridiem}
									</span>
								);
							}}
						/>

						<Table.Column
							title="Slots"
							key="slots"
							dataIndex="slots"
							align="center"
							sorter={true}
						/>

						<Table.Column
							title="Available Slots"
							key="total_available_slot"
							dataIndex="total_available_slot"
							align="center"
							width={250}
							sorter={true}
							render={(text, record) => {
								return (
									<span
										style={{
											color: parseFloat(text) === 0 ? "#FF1B1B" : "inherit",
											fontStyle: parseFloat(text) === 0 ? "italic" : "inherit",
										}}
									>
										{parseFloat(text) === 0 ? "No Available Slot" : text}
									</span>
								);
							}}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntries />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</div>
				</Col>
			</Row>

			<ModalFormEntranceExamSchedule
				toggleModalFormEntranceExamSchedule={
					toggleModalFormEntranceExamSchedule
				}
				setToggleModalFormEntranceExamSchedule={
					setToggleModalFormEntranceExamSchedule
				}
				dataSource={dataSource}
			/>
		</>
	);
}
