import {
	faCalendar,
	faCheckSquare,
	faClipboard,
	faComment,
	faFilePlus,
	faHome,
	faFileZipper,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "antd";

export const adminHeaderMenuLeft = (
	<>
		{/* <div className="ant-menu-left-icon">
            <Link to="/subscribers/current">
                <span className="anticon">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <Typography.Text>Subscribers</Typography.Text>
            </Link>
        </div> */}
	</>
);

export const adminHeaderDropDownMenuLeft = () => {
	const items = [
		// {
		//     key: "/subscribers/current",
		//     icon: <FontAwesomeIcon icon={faUsers} />,
		//     label: <Link to="/subscribers/current">Subscribers</Link>,
		// },
	];

	return <Menu items={items} />;
};

export const studentSideMenu = [
	{
		title: "Dashboard",
		path: "/student-dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
		moduleCode: "M-01",
	},
	{
		title: "Messages",
		path: "/student-messages",
		icon: <FontAwesomeIcon icon={faComment} />,
		moduleCode: "M-02",
	},
	{
		title: "Section Offering",
		path: "/student-sections",
		icon: <FontAwesomeIcon icon={faCheckSquare} />,
		moduleCode: "M-03",
	},
	{
		title: "Registration",
		path: "/student-registration",
		icon: <FontAwesomeIcon icon={faClipboard} />,
		moduleCode: "M-04",
	},
	{
		title: "Schedule",
		path: "/student-schedule",
		icon: <FontAwesomeIcon icon={faCalendar} />,
		moduleCode: "M-05",
	},
	{
		title: "Grades",
		path: "/student-grades",
		icon: <FontAwesomeIcon icon={faFilePlus} />,
		moduleCode: "M-06",
	},
	{
		title: "Entrance Exam",
		path: "/student-entrance-exam",
		icon: <FontAwesomeIcon icon={faFileZipper} />,
		moduleCode: "M-07",
	},
];
