import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Layout,
	Typography,
	Card,
	Alert,
	Form,
	Button,
	Col,
	Row,
	DatePicker,
	notification,
} from "antd";

import { logo } from "../../../providers/companyInfo";
import { encrypt } from "../../../providers/companyInfo";
import { date, description } from "../../../providers/companyInfo";

import FloatInput from "../../../providers/FloatInput";
import FloatInputPassword from "../../../providers/FloatInputPassword";
import validateRules from "../../../providers/validateRules";

import { POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageLogin() {
	const params = useParams();
	const navigate = useNavigate();
	const [formData, setFormData] = useState(null);

	const [isLogin, setIsLogin] = useState(true);

	const [errorMessageLogin, setErrorMessageLogin] = useState({
		type: "",
		message: "",
	});

	const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
		"api/login",
		"login"
	);

	const { mutate: mutateRegister, isLoading: isLoadingButtonRegister } = POST(
		"api/register",
		"register"
	);

	const onFinishLogin = (values) => {
		console.log("onFinishLogin", values);

		mutateLogin(values, {
			onSuccess: (res) => {
				if (res.data) {
					localStorage.userdata = encrypt(JSON.stringify(res.data));
					localStorage.token = res.token;
					window.location.reload();
				} else {
					setErrorMessageLogin({
						type: "error",
						message: res.message,
					});
				}
			},
			onError: (err) => {
				setErrorMessageLogin({
					type: "error",
					message: (
						<>
							Unrecognized username or password. <b>Forgot your password?</b>
						</>
					),
				});
			},
		});
	};

	const onFinishRegister = async (values) => {
		let data = new FormData();

		console.log("values", values);

		data.append("id", params.id ? params.id : "");

		if (values.user_role_id === 4) {
			let response = await fetch(`/api/generate_school_id/student`);
			let school_id = await response.text();

			data.append("school_id", school_id);
		}

		// Add Account Information
		data.append(
			"username",
			values.firstname.split(" ")[0].toLowerCase() +
				"." +
				values.lastname.split(" ")[0].toLowerCase()
		);
		data.append(
			"email",
			values.firstname.split(" ")[0].toLowerCase() +
				"." +
				values.lastname.split(" ")[0].toLowerCase() +
				"@urios.edu.ph"
		);
		data.append(
			"password",
			values.lastname
				.split(" ")
				.map(
					(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
				)
				.join(" ")
		);

		// Add Basic Information
		data.append(
			"firstname",
			values.firstname
				.split(" ")
				.map(
					(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
				)
				.join(" ")
		);

		if (values.middlename)
			data.append(
				"middlename",
				values.middlename
					.split(" ")
					.map(
						(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
					)
					.join(" ")
			);

		data.append(
			"lastname",
			values.lastname
				.split(" ")
				.map(
					(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
				)
				.join(" ")
		);

		data.append("birthdate", values.birthdate);

		// Notification
		mutateRegister(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Register ",
						description: res.message,
					});

					// navigate(`/registration/${profileId}`, { state: { ...values } });
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Layout.Content>
			<Card>
				<div className="log-in-container">
					<Col xs={24} sm={24} md={24} lg={24}>
						<Row gutter={[12, 0]}>
							<Col className="container">
								<div className="top">
									<div className="logo-wrapper zoom-in-out-box-1">
										<img src={logo} alt="fsuu.logo" />
									</div>

									<Typography.Title className="title">
										FSUU
										<p className="sub-title">
											Father Saturnino Urios University
										</p>
									</Typography.Title>
								</div>
							</Col>

							<Col>
								<Typography.Title className="text-center text-1 mt-0 mb-0">
									Student Access Module
								</Typography.Title>

								<Typography.Title className="text-center text-log-in mt-0">
									{isLogin ? "Log In" : "Register"}
								</Typography.Title>

								{isLogin ? (
									<Form
										layout="vertical"
										className="login-form"
										onFinish={onFinishLogin}
										autoComplete="off"
									>
										<Form.Item
											name="email"
											rules={[validateRules.required]}
											hasFeedback
										>
											<FloatInput
												label="Username / E-mail"
												placeholder="Username / E-mail"
											/>
										</Form.Item>
										<Form.Item
											name="password"
											rules={[validateRules.required]}
											hasFeedback
										>
											<FloatInputPassword
												label="Password"
												placeholder="Password"
											/>
										</Form.Item>

										{errorMessageLogin.message && (
											<Alert
												className="mt-10"
												type={errorMessageLogin.type}
												message={errorMessageLogin.message}
											/>
										)}

										<Button
											type="primary"
											htmlType="submit"
											loading={isLoadingButtonLogin}
											className="mt-10 w-100 btn-log-in"
											size="middle"
										>
											Log In
										</Button>

										<hr />
										<div className="register-account-wrapper">
											Don't have an account?
											<a
												onClick={() => setIsLogin(!isLogin)}
												className="mt-10 btn-toggle"
											>
												{isLogin ? "Register" : ""}
											</a>
										</div>
									</Form>
								) : (
									<>
										<Form
											layout="vertical"
											className="login-form"
											onFinish={onFinishRegister}
											autoComplete="off"
										>
											<Form.Item
												name="lastname"
												rules={[validateRules.required]}
												hasFeedback
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													required={true}
												/>
											</Form.Item>

											<Form.Item
												name="firstname"
												rules={[validateRules.required]}
												hasFeedback
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													required={true}
												/>
											</Form.Item>

											<Form.Item className="mb-0" name="middlename" hasFeedback>
												<FloatInput
													label="Middle Name"
													placeholder="Middle Name"
												/>
											</Form.Item>

											<p className="date-label mb-0">Date of Birth</p>
											<Form.Item
												className="mb-0"
												name="birthdate"
												rules={[
													validateRules.required,
													{
														validator: (_, value) => {
															const today = new Date();
															const birthdate = new Date(value);
															const age =
																today.getFullYear() - birthdate.getFullYear();

															if (age < 3 && age < 110) {
																return Promise.reject(
																	new Error("Age must be at least 3")
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<DatePicker
													size="large"
													className="w-50 mb-0"
													placeholder="Date"
													label="Date"
													format={"MM/DD/YYYY"}
												/>
											</Form.Item>
											<p className="date-format mt-0 ml-10 mb-0">MM/DD/YYYY</p>
											<Button
												type="primary"
												htmlType="submit"
												className="mt-10 w-100 btn-continue"
												size="middle"
												loading={isLoadingButtonRegister}
											>
												Continue
											</Button>
										</Form>

										<hr />
										<div className="login-account-wrapper">
											Already have an account?
											<a
												onClick={() => setIsLogin(!isLogin)}
												className="mt-10 btn-toggle"
											>
												{isLogin ? "Login" : "Login"}
											</a>
										</div>
									</>
								)}
							</Col>
						</Row>
					</Col>
				</div>
			</Card>

			<Layout.Footer>
				<Typography.Text>
					{`© ${date.getFullYear()} ${description}. All Rights
                        Reserved.`}
				</Typography.Text>
			</Layout.Footer>
		</Layout.Content>
	);
}
